import {Inject, Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs'
import {IUser} from '../application/data-types'
import {LOCAL_STORAGE} from '../application/localstorage.provider'

const ACCESS_TOKEN_NAME = 'blanco-admin-at'

/**
 * Info about the logged in state to be communicated to
 * other parts of the application
 */

export interface SpbConfiguration {
  /**
   * For the lazy ones, just check the admin status
   */
  admin: boolean

  /**
   * The access token for those who need it.
   */
  token?: string

  /**
   * The current user, if any
   */
  currentUser?: IUser
}


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public configState: BehaviorSubject<SpbConfiguration> = new BehaviorSubject<SpbConfiguration>({admin: false})

  private currentConfig: SpbConfiguration = {admin: false}

  constructor(
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage,
  ) {
    this.isLoggedIn()
  }

  /**
   * Checks if user is logged in.
   */
  public isLoggedIn(): void {
    const loginStateString = this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME)
    try {
      const token = JSON.parse(loginStateString)
      this.setToken(token.token)
    } catch (e) {
      // Can happen and is quite common.
    }
  }

  /**
   * Set the authentication token
   *
   * @param token - The token as received from the login service
   */
  public setToken(token: string): void {
    const loginState = {
      payload: JSON.parse(atob(token.split('.')[1])),
      timeStamp: new Date().getTime() + 60 * 60 * 12 * 1000, // Live for 12 hours
      token
    }
    this.currentConfig = {
      admin: loginState.payload.exp > new Date().getTime() && loginState.payload.roles.indexOf('blancoAdmin') !== -1,
      token,
    }
    this.configState.next(this.currentConfig)
    this.injectedLocalStorage.setItem(ACCESS_TOKEN_NAME, JSON.stringify(loginState))
  }

  /**
   * Reset what ever access token we might have had
   */
  public resetToken(): void {
    this.injectedLocalStorage.removeItem(ACCESS_TOKEN_NAME)
    this.currentConfig.admin = false
    this.currentConfig.token = null
    this.currentConfig.currentUser = null
    this.configState.next(this.currentConfig)
  }
}
