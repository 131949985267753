import {BrowserModule} from '@angular/platform-browser'
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {CustomMaterialsModule} from './custom-materials-module'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {SpbCommonModule} from './common/common.module'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {LOCAL_STORAGE_PROVIDERS} from './application/localstorage.provider'
import {GlobalErrorHandler} from './application/error-hanlder'
import '@angular/common/locales/global/fr'
import {HttpsInterceptor} from './application/https-interceptor.service'


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CustomMaterialsModule,
    HttpClientModule,
    SpbCommonModule,
  ],
  providers: [
    WINDOW_PROVIDERS,
    LOCAL_STORAGE_PROVIDERS,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {provide: LOCALE_ID, useValue: 'fr'},
    {provide: HTTP_INTERCEPTORS, useClass: HttpsInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

