<div>
  <div class="top-holder">
    <div class="top">
      <div (click)="navigate('https://internetbanken.sparbankensyd.se/')" class="internetbanken">
        <div>
          <em class="material-icons">
            lock_outline
          </em>
        </div>
        <div>
          Internetbanken
        </div>
      </div>
    </div>
  </div>
  <div class="middle-holder">
    <div class="middle">
      <div (click)="navigate('https://www.sparbankensyd.se/')" class="logo">
        Sparbanken Syd
      </div>
      <div class="filler"></div>
      <div *ngIf="isAdmin" class="menu-button">
        <button [matMenuTriggerFor]="menu" mat-button>
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button [routerLink]="['/admin']" mat-menu-item>Admin</button>
          <button (click)="configService.resetToken()" mat-menu-item>Logout</button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
