import {Injectable} from '@angular/core'
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {catchError} from 'rxjs/operators'
import {Observable, throwError} from 'rxjs'
import {ConfigService} from '../services/config.service'

/**
 * Intended to catch e.g. 401 responses and if so re-route to home.
 */
@Injectable()
export class HttpsInterceptor implements HttpInterceptor {

  constructor(private configService: ConfigService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.configService.resetToken()
        }
        return throwError(error)
      })
    )
  }
}
