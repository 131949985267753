import {Component, Inject, OnDestroy, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {Subscription, timer} from 'rxjs'
import {map, take} from 'rxjs/operators'
import {CollectResponse, LoginService} from '../../services/login.service'
import {SignDialogData} from '../../application/data-types'

@Component({
  selector: 'spb-sign-dialog',
  templateUrl: './sign-dialog.component.html',
})
export class SignDialogComponent implements OnInit, OnDestroy {

  /**
   * Temp for test
   */
  public statusText: string = 'Starta BankID på din mobila enhet'

  /**
   */
  public errorMessage: string

  /**
   * The subscription to check for BankID completion.
   */
  private countDown: Subscription

  constructor(public dialogRef: MatDialogRef<SignDialogComponent>,
              private loginService: LoginService,
              @Inject(MAT_DIALOG_DATA) public data: SignDialogData
  ) {
  }

  ngOnInit(): void {
    /**
     * When we start we start to look for completion of BankID sign.
     */

    this.countDown = timer(0, 2000)
      .pipe(
        take(20), // = 200 seconds, just over three minutes. Should be sufficient??
        map(() => {
          this.loginService.collect(this.data.type, this.data.orderRef).subscribe({
            next: (response: CollectResponse) => {
              if (response.hintCode === 'outstandingTransaction') {
                this.statusText = this.data.startText
              }
              if (response.hintCode === 'userSign') {
                this.statusText = this.data.actionText
              }

              // This only comes when doing sign.
              if (response.status === 'complete') {
                this.statusText = this.data.doneText
                this.countDown.unsubscribe()
                this.dialogRef.close({status: 'ok', accessToken: response.accessToken})
              }

              // This is temporary before we fix the login service
              // To return status complete.
              if (response.accessToken) {
                this.countDown.unsubscribe()
                this.dialogRef.close({status: 'ok', accessToken: response.accessToken})
              }
            },
            error: error => {
              this.countDown.unsubscribe()
              this.dialogRef.close({status: 'error', code: error.status})
            }
          })
        })
      )
      .subscribe() // Subscribe to the timer.
  }

  cancel(): void {
    this.loginService.cancel(this.data.orderRef).subscribe()
  }

  ngOnDestroy() {
    // Unsubscribe to avoid leakage.
    this.countDown.unsubscribe()
  }
}
