import {Component, Input} from '@angular/core'
import {LoginService, StartLoginResponse} from '../../services/login.service'
import {WaitDialogComponent} from '../wait-dialog/wait-dialog.component'
import {SignDialogComponent} from '../sign-dialog/sign-dialog.component'
import {MatDialog} from '@angular/material/dialog'
import {ConfigService} from '../../services/config.service'

/**
 * Calls BankID as necessary and when done (or failed)
 * it emits an event that (someone) can listen to.
 */
@Component({
  selector: 'spb-bankid',
  templateUrl: './bank-id.component.html',
  styleUrls: ['./bank-id.component.scss']
})
export class BankIdComponent {
  /**
   * The personnummer to use when logging in.
   */
  @Input() public personNummer: string

  /**
   * This is so that you can set the button label as you wish.
   */
  @Input() public label = 'Logga in'

  /**
   * A general error message
   */
  public errorMessage = ''

  /**
   * Details of the error.
   */
  public errorDetail = ''

  /**
   * Default constructor
   */
  constructor(
    private configService: ConfigService,
    private loginService: LoginService,
    private dialog: MatDialog,
  ) {
  }

  /**
   * Starts the login flow unless we are already logged.
   */
  login(): void {
    let waitRef = this.dialog.open(WaitDialogComponent, {
      disableClose: true
    })
    this.loginService.startLogin(this.personNummer)
      .subscribe({
        next: (startResponse: StartLoginResponse) => {
          waitRef.close()
          if (!startResponse.orderRef) {
            this.errorMessage = 'Vi kunde inte starta signering hos BankID. Kontrollera ditt personnummer och försök igen'
            return
          }
          waitRef = this.dialog.open(SignDialogComponent, {
            width: '90%',
            maxWidth: '400px',
            disableClose: true,
            data: {
              type: 'login',
              orderRef: startResponse.orderRef,
              title: 'Legitimera',
              startText: 'Starta BankID-programmet på den enhet du har det installerat',
              actionText: 'Skriv in din säkerhetskod i BankID-appen och välj Legitimera.',
              doneText: 'Legitimering klar. Ha en fin dag!'
            }
          })
          waitRef.afterClosed().subscribe({
            next: (res => {
              this.configService.setToken(res.accessToken)
            })
          })
        }
      })
  }
}
