// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  loginServiceUrl: 'https://gulapuckar-www-daniel.lana.sparbankensyd.se/api',
  userServiceUrl: 'https://ab-daniel.internal.sparbankensyd.se/service',
  authServiceUrl: 'https://gulapuckar-www-daniel.lana.sparbankensyd.se/service',
  domain: 'blanco-test.lana.sparbankensyd.se',
  showDebug: true
}
