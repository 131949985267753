import {Component, Inject, Input, NgZone, OnInit} from '@angular/core'
import {Router} from '@angular/router'
import {WINDOW} from '../../application/window.provider'

/**
 * Component to display the bread crumbs.
 */
@Component({
  selector: 'spb-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  /**
   * The path to display.
   */
  @Input() path: string

  /**
   * An array
   */
  @Input() links: Array<string>

  /**
   * The list of destinations connected to the breadcrumbs.
   */
  public elements: Array<string> = []

  /**
   * Default route address
   */
  readonly DEFAULT_ADDRESS = 'https://www.sparbankensyd.se'

  /**
   * Constructor
   *
   * @param router - Needed to route internally
   * @param ngZone - Needed to prevent 'Navigation outside ... warning in tests.
   * @param injectedWindow - Needed to route to new location
   */
  constructor(
    private router: Router,
    private ngZone: NgZone,
    @Inject(WINDOW) private injectedWindow: Window,
  ) {
  }

  /**
   * Create an array of the passed in string of path. Anything
   * to be listed after 'Sparbanken Syd | ' e.g 'Låna | Lånelöfte
   * spaces are significant.
   */
  ngOnInit() {
    this.elements = this.path.split('|')
  }

  /**
   * A navigation wrapper to be able to navigate. Navigates externally
   * if the link contains 'https://'
   *
   * @param position - Position in the array of links to route to.
   */
  navigate(position: number): void {
    if (position === -1) {
      this.injectedWindow.location.href = this.DEFAULT_ADDRESS
      return
    }
    if (this.links[position].indexOf('https://') !== -1) {
      this.injectedWindow.location.href = this.links[position]
    } else {
      // When routing inside a subscription callback we need NgZone to avoid warnings when testing?!
      this.ngZone.run(() => this.router.navigate([this.links[position]]))
    }
  }
}
