import {Component} from '@angular/core'

@Component({
  template: `<title matDialogTitle>Erbjudande</title>
  Vi räknar på ett erbjudande, det tar någon minut...`
})
export class CalculateDialogComponent {
  constructor() {
  }
}
