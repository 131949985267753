import {Component} from '@angular/core'

@Component({
  selector: 'spb-wait-dialog',
  template: `<title matDialogTitle>Förbereder</title>
  Vi förbereder BankID, ett ögonblick...`
})
export class WaitDialogComponent {
  constructor() {
  }
}
