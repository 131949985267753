import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {HeaderComponent} from './header/header.component'
import {FooterComponent} from './footer/footer.component'
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component'
import {CustomMaterialsModule} from '../custom-materials-module'
import {CommonRoutingModule} from './common-routing.module'
import {FormsModule} from '@angular/forms'
import {BankIdComponent} from './bankid/bank-id.component'
import {SignDialogComponent} from './sign-dialog/sign-dialog.component'
import {WaitDialogComponent} from './wait-dialog/wait-dialog.component'
import {MatDialogModule} from '@angular/material/dialog'
import {CalculateDialogComponent} from './calculate-dialog/calculate-dialog.component'

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BreadcrumbsComponent,
    BankIdComponent,
    SignDialogComponent,
    WaitDialogComponent,
    CalculateDialogComponent,
  ],
  imports: [
    CommonModule,
    CommonRoutingModule,
    CustomMaterialsModule,
    FormsModule,
    MatDialogModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    BreadcrumbsComponent,
    BankIdComponent,
    SignDialogComponent,
    WaitDialogComponent,
    CalculateDialogComponent,
  ],
})
export class SpbCommonModule {
}
