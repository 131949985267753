import {Component} from '@angular/core'
import version from '../../../assets/package.json'

@Component({
  selector: 'spb-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public version = version.version
}
