<title matDialogTitle>{{data.title}}</title>
{{statusText}}
<mat-dialog-actions>
  <button [matDialogClose]="{status: 'cancel'}"
          (click)="cancel()"
          color="primary"
          mat-raised-button
  >Avbryt
  </button>
</mat-dialog-actions>
