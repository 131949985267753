<div class="mat-typography">
  <div *ngIf="errorMessage" class="spb-error">
    {{errorMessage}}
  </div>
  <form #f="ngForm" (ngSubmit)="login()">
    <div class="input">
      <mat-form-field class="" floatLabel="always" hideRequiredMarker>
        <mat-label>Personnummer</mat-label>
        <label>
          <input
            [(ngModel)]="personNummer"
            matInput
            maxlength="13"
            minlength="10"
            name="personNummer"
            placeholder="ÅÅÅÅMMDD-NNNN"
            required
            type="tel"/>
        </label>
      </mat-form-field>
      <button
        mat-raised-button color="primary"
        [disabled]="!f.form.valid">
        {{label}}
      </button>
    </div>
  </form>
</div>
